import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDpgFl1cXigAQ_p1axUuR6ik2DbrR8ZL9Q",
    authDomain: "patterns-config.firebaseapp.com",
    databaseURL: "https://patterns-config-default-rtdb.firebaseio.com",
    projectId: "patterns-config",
    storageBucket: "patterns-config.appspot.com",
    messagingSenderId: "418027301683",
    appId: "1:418027301683:web:d999d693c35da5cbdaec46"
};

firebase.initializeApp(firebaseConfig);
let database = firebase.database()

export { database };