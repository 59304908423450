<template>
  <div>
    <!-- Before Tone Mount -->
    <div v-if="!mounted && !started" class="flex h-screen justify-center items-center">
      <p class="absolute top-10 border border-b-2 p-3">Before clicking the button below please plug in a MIDI keyboard. The app doesn't really do anything if you don't have one :(</p> 
      <button
        class="inline-flex items-center px-6 py-2.5 border border-transparent text-m font-medium rounded text-kilamanjaro bg-fawn hover:bg-fawn-dark"
        @click="mountTone"
      >
        Initialize
      </button>
    </div>
    <!-- Before Tone Start -->
    <div v-if="mounted && !started" class="flex h-screen justify-center items-center">
      <p class="absolute top-10 border border-b-2 p-3">One more button. Browsers are weird about audio.</p> 
      <button
        class="inline-flex items-center px-6 py-2.5 border border-transparent text-m font-medium rounded text-kilamanjaro bg-fawn hover:bg-fawn-dark"
        @click="startTone"
      >
        Start
      </button>
    </div>
    <!-- Libraries Ready -->
    <div v-if="mounted && started" class="grid grid-flow-row grid-cols-4 gap-8 p-6">
      <!-- Controls -->
      <div class="col-start-1 col-span-1 fixed">
        <h1 class="text-2xl">Patterns</h1>
        <p class="text-xs w-60">Design and record music to be used with the Everything A Cycle library</p>

        <!-- Patch Selector -->
        <div class="py-4">
          <label class="mb-2">Patch</label>
          <button 
            v-for="(synth, index) in synths"
            :key="index"
            class="patch-selector focus:outline-none"
            :class="{'bg-fawn': index === activePatch}" 
            @click="activePatch = index"
            >{{index}}</button>
        </div>

        <!-- Mode Selector -->
        <div class="py-4">
          <label class="mb-2">Mode</label>
          <button
            class="patch-selector text-xs focus:outline-none"
            :class="{'bg-fawn': mode === 'configure'}" 
            @click="mode = 'configure'"
          >Design</button>
          <button
            class="patch-selector text-xs focus:outline-none"
            :class="{'bg-fawn': mode === 'record'}" 
            @click="mode = 'record'"

          >Compose</button>        
        </div>

        <!-- Universals
        <div class="py-4">
          <label class="mb-2">Configuration</label>
          <span class="mt-2 font-light text-sm block">BPM</span>
          <input class="mt-2 text-xs text-center p-1 w-8" v-model="bpm">
        </div> -->

      </div>

      <!-- Patch Container -->
      <div class="col-start-2 col-span-3">
        <patch 
          v-for="(synth, index) in synths" 
          v-show="activePatch == index"
          :ref="index"
          :key="index" 
          :patchId="index" 
          :toneReference="Tone" 
          :mode="mode"
          :bpm="bpm"
          :isActive="activePatch == index"
          ></patch>
      </div>
    </div>
  </div>
</template>

<script>
import Patch from "@/views/Patch.vue";

export default {
  name: "Home",
  components: {
    Patch,
  },
  data() {
    return {
      Tone: {},
      mounted: false,
      started: false,
      mode: "configure",
      activePatch: 0,
      bpm: 60,
      synths: [{}, {}, {}]
    };
  },
  methods: {
    changePatch() {
      let newPatch = this.selectedPatch;

      this.patchName = newPatch.patchName;
      this.partials = newPatch.partials;
      this.envelope = newPatch.envelope;
      this.eq3Config = newPatch.eq3Config;
      this.chorusConfig = newPatch.chorusConfig;
      this.tremoloConfig = newPatch.tremoloConfig;
      this.vibratoConfig = newPatch.vibratoConfig;
      this.pingPongDelayConfig = newPatch.pingPongDelayConfig;
      this.reverbConfig = newPatch.reverbConfig;
      this.feedbackDelayConfig = newPatch.feedbackDelayConfig;
    },
    mountTone() {
      this.Tone = require('tone');
      this.mounted = true;
    },
    startTone() {
      this.Tone.Transport.start()
      this.Tone.Transport.bpm.value = 60;
      this.started = true;
    }
  },
  watch: {
    bpm(newBpm) {
      this.bpm = newBpm
      this.Tone.Transport.bpm.value = this.bpm
    }
  }
};
</script>

<style lang="scss"></style>
