<template>
  <div>
    <div class="">
      <div class="section-header">
        <h2 class="text-lg">Controls</h2>
        <p class="text-xs mb-2 font-light">Record a part and play it back. Returning to 'Design' tab allows you to edit while the loops plays.</p>
      </div>
      <div class="controls">
        <div class="flex flex-row">
          <div class="mr-4">
            <label class="mb-2">Record Loop</label>
            <button class="small-btn" v-if="!recording" @click="startRecording">
              Start
            </button>
            <button class="small-btn" v-if="recording" @click="stopRecording">
              Stop
            </button>
          </div>
          <div class="ml-6">
            <label class="mb-2">Play Loop</label>
            <button class="small-btn" v-if="!playing" @click="playback">
              Start
            </button>
            <button class="small-btn" v-if="playing" @click="stopPlayback">
              Stop
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <div class="section-header">
        <h2 class="text-lg">Loop Meta</h2>
        <p class="text-xs mb-2 font-light">Control loop meta information. Time is in Bars:Beats:Sixteenths. BPM is 60.</p>
      </div>
      <div class="controls">
        <label class="">Time Between Loops</label>
        <input class="mt-2 p-2 w-14 text-center text-xs bg-moon-light" v-model="rest" />
      </div>
    </div>

    <div class="mt-8">
      <div class="section-header">
        <h2 class="text-lg">Composition</h2>
        <p class="text-xs mb-2 font-light">Notes in recorded loop. All fields can be edited. Time is in Bars:Beats:Sixteenths. BPM is 60. Velocity is relative loudness.</p>
      </div>
      <div class="controls">
        <label class="mt-6">Events</label>
        <div class="flex flex-row flex-wrap flow mb-20">
          <div
            class="w-24 border p-4 mr-4 mt-2"
            v-for="(event, index) in composition.events"
            :key="index"
            :class="{ 'bg-fawn-light': event.active }"
          >
            <span class="mt-2 font-light text-sm block">Note</span>
              <input class="w-16 p-1 text-center text-xs bg-moon-light" v-model="event.note" />
            <span class="mt-2 font-light text-sm block">Start</span>
            <input class="w-16 p-1 text-center text-xs bg-moon-light" v-model="event.time" />
            <span v-if="event.duration">
              <span class="mt-2 font-light text-sm block">Duration</span>
              <input class="w-16 p-1 text-center text-xs bg-moon-light" v-model="event.duration" />
            </span>
            <span class="mt-2 font-light text-sm block">Velocity</span>
            <input class="w-16 p-1 text-center text-xs bg-moon-light" v-model="event.velocity" />
          </div>
        </div>
      </div>
    </div>

    <div class="fixed w-full p-4 h-14 bottom-0 left-0 bg-moon-dark">
      <button class="small-btn absolute right-10 text-xs" @click="getCode">
        Get Code
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["composition"],
  data() {
    return {
      rest: '0:0:0',
      recording: false,
      playing: false
    };
  },
  watch: {
    rest() {
      this.$emit("restChange", this.rest);
    }
  },
  methods: {
    getCode() {
      let config = { composition: this.composition };
      navigator.clipboard.writeText(JSON.stringify(config)).then(
        function() {
          alert("Composition saved to clipboard");
        },
        function() {}
      );
    },
    startRecording() {
      this.recording = true;
      this.$emit("startRecording");
    },
    stopRecording() {
      this.recording = false;
      this.$emit("stopRecording");
    },
    playback() {
      this.playing = true;
      this.$emit("startPlayback");
    },
    stopPlayback() {
      this.playing = false;
      this.$emit("stopPlayback");
    }
  }
};
</script>
