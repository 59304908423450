<template>
  <div class="">
    <div>
      <div class="section-header">
        <h2 class="text-lg">Identification</h2>
        <p class="text-xs mb-2 font-light">Select a saved patch or randomize values to create a starting point</p>
      </div>
      <p class="my-4 text-xs inline-block">
        Name: <input class="ml-2 p-2 text-xs bg-moon-light" v-model="patchName" />
      </p>
      <p class="text-xs inline-block ml-6">Select Saved:</p>
      <select
        class="ml-2 inline-block w-60 text-xs bg-moon-light p-2"
        v-model="selectedPatch"
        @change="changePatch"
      >
        <option
          class=""
          v-for="patch in allPatches"
          :key="patch.id"
          :value="patch"
          >{{ patch.patchName }}
        </option>
      </select>
      <button class="small-btn text-xs ml-8" @click="generateName">
        Randomize Values
      </button>
    </div>
    <!-- Volume -->
   <div class="mt-8">
      <div class="section-header">
        <h2 class="text-lg">Volume</h2>
        <p class="text-xs mb-2 font-light">Set synth output volume</p>
      </div>
      <div class="grid grid-cols-3 gap-8">
        <!-- Gain -->
        <div class="controls col-span-2">
          <h3>Volume</h3>
          <label>Gain</label>
          <input
            type="range"
            min="0.01"
            max="1"
            step="0.01"
            v-model.number="gainConfig.gain"
          />
        </div>
      </div>
   </div>
    <!-- Synth -->
    <div class="mt-8">
      <div class="section-header">
        <h2 class="text-lg">Voice</h2>
        <p class="text-xs mb-2 font-light">Configure synthesizer voice by changing oscillator timbre, envelope, and audible frequencies</p>
      </div>
      <div class="grid grid-cols-3 gap-6">
        <!-- Oscillator Partials -->
        <div class="controls">
          <h3>Oscillator</h3>
          <label>Octaves</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.005"
            v-model.number="partialsConfig[index]"
            :key="index"
            v-for="(partial, index) in partialsConfig"
          />
        </div>
        <!-- Envelope -->
        <div class="controls">
          <h3>Envelope</h3>
          <label>Attack</label>
          <input
            type="range"
            min="0"
            max="6"
            step="0.1"
            v-model.number="envelopeConfig.attack"
          />
          <label>Decay</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="envelopeConfig.decay"
          />
          <label>Sustain</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="envelopeConfig.sustain"
          />
          <label>Release</label>
          <input
            type="range"
            min="0"
            max="6"
            step="0.1"
            v-model.number="envelopeConfig.release"
          />
        </div>
        <!-- EQ3 -->
        <div class="controls">
          <h3>EQ3 Filter</h3>
          <label>Low Cutoff</label>
          <input
            type="range"
            min="0"
            max="500"
            step="1"
            v-model.number="eq3Config.lowFrequency"
          />
          <label>Low Volume</label>
          <input
            type="range"
            min="-30"
            max="0"
            step="1"
            v-model.number="eq3Config.low"
          />
          <label>Middle Volume</label>
          <input
            type="range"
            min="-30"
            max="0"
            step="1"
            v-model.number="eq3Config.mid"
          />
          <label>High Cutoff</label>
          <input
            type="range"
            min="1000"
            max="5000"
            step="1"
            v-model.number="eq3Config.highFrequency"
          />
          <label>High Volume</label>
          <input
            type="range"
            min="-30"
            max="0"
            step="1"
            v-model.number="eq3Config.high"
          />
        </div>
      </div>
    </div>
    <!-- Voice Effects -->
    <div class="mt-8">
      <div class="section-header">
        <h2 class="text-lg">Effects</h2>
        <p class="text-xs mb-2 font-light">Add effects to synthesizer voice</p>
      </div>
      <div class="grid grid-cols-3 gap-8">
        <!-- Tremolo -->
        <div class="controls">
          <h3>Tremolo</h3>
          <label>Frequency</label>
          <input
            type="range"
            min="0"
            max="20"
            step="1"
            v-model.number="tremoloConfig.frequency"
          />
          <label>Depth</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            v-model.number="tremoloConfig.depth"
          />
          <label>Spread</label>
          <input
            type="range"
            min="0"
            max="180"
            step="1"
            v-model.number="tremoloConfig.spread"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            v-model.number="tremoloConfig.wet"
          />
        </div>
        <!-- Vibrato -->
        <div class="controls">
          <h3>Vibrato</h3>
          <label>Delay</label>
          <input
            type="range"
            min="0.001"
            max="0.02"
            step=".001"
            v-model.number="vibratoConfig.maxDelay"
          />
          <label>Frequency</label>
          <input
            type="range"
            min="0"
            max="10"
            step=".5"
            v-model.number="vibratoConfig.frequency"
          />
          <label>Depth</label>
          <input
            type="range"
            min="0"
            max="1"
            step=".05"
            v-model.number="vibratoConfig.depth"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0"
            max="1"
            step=".05"
            v-model.number="vibratoConfig.wet"
          />
        </div>
        <!-- Chorus -->
        <div class="controls">
          <h3>Chorus</h3>
          <label>Delay Time</label>
          <input
            type="range"
            min="0.001"
            max="20"
            step="0.5"
            v-model.number="chorusConfig.delayTime"
          />
          <label>Depth</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="chorusConfig.depth"
          />
          <label>Feedback</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="chorusConfig.feedback"
          />
          <label>Frequency</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="chorusConfig.frequency"
          />
          <label>Spread</label>
          <input
            type="range"
            min="0"
            max="180"
            step="1"
            v-model.number="chorusConfig.spread"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="chorusConfig.wet"
          />
        </div>
      </div>
    </div>
    <!-- Room Effects -->
    <div class="mt-8 mb-16">
      <div class="section-header">
        <h2 class="text-lg">Room Effects</h2>
        <p class="text-xs mb-2 font-light">Add effects to the 'room' to give the synthesizer a sense of place</p>
      </div>
      <div class="grid grid-cols-3 gap-8">
        <!-- Ping Pong Delay -->
        <div class="controls">
          <h3>Ping Pong Delay</h3>
          <label>Delay</label>
          <input
            type="range"
            min="0.01"
            max="2"
            step="0.01"
            v-model.number="pingPongDelayConfig.delayTime"
          />
          <label>Feedback</label>
          <input
            type="range"
            min="0.01"
            max="1"
            step="0.01"
            v-model.number="pingPongDelayConfig.feedback"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="pingPongDelayConfig.wet"
          />
        </div>
        <!-- Feedback Delay -->
        <div class="controls">
          <h3>Feedback Delay</h3>
          <label>Delay</label>
          <input
            type="range"
            min="0.001"
            max="1"
            step="0.01"
            v-model.number="feedbackDelayConfig.delayTime"
          />
          <label>Feedback</label>
          <input
            type="range"
            min="0.001"
            max="1"
            step="0.01"
            v-model.number="feedbackDelayConfig.feedback"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            v-model.number="feedbackDelayConfig.wet"
          />
        </div>
        <!-- Reverb -->
        <div class="controls">
          <h3>Reverb</h3>
          <label>Decay</label>
          <input
            type="range"
            min="0.01"
            max="10"
            step="0.1"
            v-model.number="reverbConfig.decay"
          />
          <label>Strength</label>
          <input
            type="range"
            min="0.5"
            max="1"
            step="0.05"
            v-model.number="reverbConfig.wet"
          />
        </div>
      </div>

      <div class="fixed w-full p-4 h-14 bottom-0 left-0 bg-moon-dark">
        <button class="small-btn absolute right-40 text-xs" @click="getCode">
          Get Code
        </button>
        <button class="small-btn absolute right-10 text-xs" @click="savePatch">
          Save Patch
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PingPongDelay } from 'tone';
import { database } from "/src/firebase.js";

export default {
  data() {
    return {
      patchName: "",
      selectedPatch: {},
      allPatches: [],
      partialsConfig: [1, 0, 0, 0, 0, 0, 0, 0, 0],
      envelopeConfig: {
        attack: 1.5,
        attackCurve: "linear",
        decay: 0.4,
        sustain: 0.5,
        release: 0.5,
        releaseCurve: "linear",
      },
      eq3Config: {
        low: 0,
        lowFrequency: 440,
        mid: 0,
        high: 0,
        highFrequency: 2500,
      },
      chorusConfig: {
        delayTime: 0.001,
        depth: 0,
        feedback: 0,
        frequency: 0,
        spread: 0,
        wet: 0,
      },
      tremoloConfig: {
        frequency: 0,
        depth: 0,
        spread: 0,
        wet: 0,
      },
      vibratoConfig: {
        maxDelay: 0.001,
        frequency: 0,
        depth: 0,
        wet: 0,
      },
      pingPongDelayConfig: {
        delayTime: 0.001,
        feedback: 0,
        maxDelay: 5,
        wet: 0,
      },
      reverbConfig: {
        decay: 0.001,
        wet: 0,
      },
      feedbackDelayConfig: {
        maxDelay: 15,
        delayTime: 0.001,
        feedback: 0,
        wet: 0,
      },
      gainConfig: {
        gain: 1.0
      }
    };
  },
  methods: {
    getCode() {
      let config = {patchName: this.patchName, partialsConfig: this.partialsConfig, envelopeConfig: this.envelopeConfig, eq3Config: this.eq3Config, chorusConfig: this.chorusConfig, tremoloConfig: this.tremoloConfig, vibratoConfig: this.vibratoConfig, pingPongDelayConfig: this.pingPongDelayConfig, reverbConfig: this.reverbConfig, feedbackDelayConfig: this.feedbackDelayConfig, gainConfig: this.gainConfig }
      navigator.clipboard.writeText(JSON.stringify(config)).then(function() {
        alert('Patch configuration copied to clipboard')
      }, function() {
      });
    },
    changePatch() {
      let newPatch = this.selectedPatch;
      this.patchName = newPatch.patchName;
      this.partialsConfig = newPatch.partials;
      this.envelopeConfig = newPatch.envelope;
      this.eq3Config = newPatch.eq3Config;
      this.chorusConfig = newPatch.chorusConfig;
      this.tremoloConfig = newPatch.tremoloConfig;
      this.vibratoConfig = newPatch.vibratoConfig;
      this.pingPongDelayConfig = newPatch.pingPongDelayConfig;
      this.reverbConfig = newPatch.reverbConfig;
      this.feedbackDelayConfig = newPatch.feedbackDelayConfig;
    },
    getSavedPatches() {
      let patchesRef = database.ref("patches");

      patchesRef.on("child_added", (data) => {
        this.allPatches.push(data.val());
      });
    },
    generateName() {
      let adjectives = [
        "aged",
        "ancient",
        "calm",
        "crimson",
        "delicate",
        "dawn",
        "falling",
        "gentle",
        "green",
        "hidden",
        "lingering",
        "misty",
        "morning",
        "nameless",
        "patient",
        "polished",
        "restless",
        "snowy",
        "shy",
        "sparkling",
        "still",
        "twilight",
        "weathered",
        "withered",
        "unknown",
        "unknowable",
        "sacred",
        "forgotten",
        "seismic",
        "connected",
        "radiant",
        "submerged",
        "cosmic",
        "holographic",
        "reality",
        "physical",
        "encoded",
        "digital",
        "ascended",
        "astral",
        "clairvoyant",
        "electronic",
        "guided",
        "harmonious",
        "light",
        "spontaneous",
        "unexplained",
        "virtual",
      ];
      let nouns = [
        "soul",
        "grid",
        "altruism",
        "wheel",
        "comet",
        "neutrino",
        "hall",
        "miracle",
        "dimensions",
        "flower",
        "harmony",
        "affirmation",
        "symbol",
        "archetype",
        "plane",
        "avatar",
        "aura",
        "triangle",
        "cave",
        "breeze",
        "cell",
        "aphorism",
        "flower",
        "firefly",
        "glade",
        "glitter",
        "haze",
        "heart",
        "hill",
        "meadow",
        "math",
        "chemistry",
        "physics",
        "mode",
        "moon",
        "pond",
        "night",
        "resonance",
        "silence",
        "sound",
        "star",
        "sunset",
        "union",
        "wave",
        "cosmos",
        "talisman",
        "dreams",
        "lens",
        "radio",
        "galaxy",
        "scientist",
        "poet",
        "researcher",
        "computer",
        "oracle",
        "logic",
        "crystal",
        "pendulum",
        "simulation",
        "reality",
        "hologram",
        "hypothesis",
        "fabric",
      ];

      let title = `${
        adjectives[Math.floor(Math.random() * adjectives.length)]
      } ${nouns[Math.floor(Math.random() * nouns.length)]}`;
      this.patchName = title;
      this.randomizeValues();
    },
    randomizeValues() {
      this.partialsConfig = [
        Math.random(),
        Math.random() * 0.8,
        Math.random() * 0.8,
        Math.random() * 0.8,
        Math.random() * 0.6,
        Math.random() * 0.6,
        Math.random() * 0.4,
        Math.random() * 0.4,
        Math.random() * 0.2,
      ];
      this.envelopeConfig = {
        attack: Math.random() * 5,
        decay: Math.random(),
        sustain: Math.random(),
        release: Math.random() * 5,
      };
      this.eq3Config = {
        low: Math.random() * -20,
        lowFrequency: Math.random() * 440,
        mid: Math.random() * -20,
        high: Math.random() * -20,
        highFrequency: Math.random() * 2500,
      };
      this.chorusConfig = {
        delayTime: Math.random() * 5,
        depth: Math.random(),
        feedback: Math.random() * 0.2,
        frequency: Math.random() * 1.5,
        spread: Math.random() * 180,
        wet: Math.random() * 0.75,
      };
      this.tremoloConfig = {
        frequency: Math.random() * 10,
        depth: Math.random() * 0.75,
        spread: Math.random() * 180,
        wet: Math.random() * 0.75,
      };
      this.vibratoConfig = {
        maxDelay: Math.random() * 0.01,
        frequency: Math.random() * 5,
        depth: Math.random() * 0.4,
        wet: Math.random() * 0.75,
      };
      this.pingPongDelayConfig = {
        delayTime: Math.random() * 0.5,
        maxDelay: Math.random() * 5,
        wet: Math.random() * 0.75,
      };
      this.reverbConfig = {
        decay: Math.random() * 1.5,
        wet: Math.random() * 0.75,
      };
      this.feedbackDelayConfig = {
        delay: Math.random() * 0.5,
        feedback: Math.random() * 0.5,
      };
    },
    savePatch() {
      database
        .ref("patches")
        .push()
        .set({
          patchName: this.patchName,
          partials: this.partialsConfig,
          envelope: this.envelopeConfig,
          eq3Config: this.eq3Config,
          chorusConfig: this.chorusConfig,
          tremoloConfig: this.tremoloConfig,
          vibratoConfig: this.vibratoConfig,
          pingPongDelayConfig: this.pingPongDelayConfig,
          reverbConfig: this.reverbConfig,
          feedbackDelayConfig: this.feedbackDelayConfig,
          gainConfig: this.gainConfig
        })
        .then( _ => {
          alert('Patch Saved')
        })
    },
  },
  watch: {
    partialsConfig() {
      let partialArray = [];
      this.partialsConfig.forEach((partial) => {
        partialArray.push(partial);
      });

      this.$emit("partialsChange", partialArray);
    },
    envelopeConfig: {
      deep: true,
      handler() {
        this.$emit("envelopeChange", this.envelopeConfig);
      },
    },
    tremoloConfig: {
      deep: true,
      handler() {
        this.$emit("tremoloChange", this.tremoloConfig);
      },
    },
    vibratoConfig: {
      deep: true,
      handler() {
        this.$emit("vibratoChange", this.vibratoConfig);
      },
    },
    eq3Config: {
      deep: true,
      handler() {
        this.$emit("eq3Change", this.eq3Config);
      },
    },
    chorusConfig: {
      deep: true,
      handler() {
        this.$emit("chorusChange", this.chorusConfig);
      },
    },
    pingPongDelayConfig: {
      deep: true,
      handler() {
        this.$emit("pingPongDelayChange", this.pingPongDelayConfig);
      },
    },
    feedbackDelayConfig: {
      deep: true,
      handler() {
        this.$emit("feedbackDelayChange", this.feedbackDelayConfig);
      },
    },
    reverbConfig: {
      deep: true,
      handler() {
        this.$emit("reverbChange", this.reverbConfig);
      },
    },
    gainConfig: {
      deep: true,
      handler() {
        this.$emit("gainChange", this.gainConfig)
      }
    }
  },
  mounted() {
    this.getSavedPatches()
  }
};
</script>
