<template>
  <div class="">
    <configure
      v-show="mode === 'configure'"
      @partialsChange="updatePartials"
      @envelopeChange="updateEnvelope"
      @tremoloChange="updateTremolo"
      @vibratoChange="updateVibrato"
      @eq3Change="updateEq3"
      @chorusChange="updateChorus"
      @pingPongDelayChange="updatePingPongDelay"
      @feedbackDelayChange="updateFeedbackDelay"
      @reverbChange="updateReverb"
      @gainChange="updateGain"
    ></configure>
    <record
      v-show="mode === 'record'"
      :composition="composition"
      @startRecording="startRecording"
      @stopRecording="stopRecording"
      @startPlayback="startPlayback"
      @stopPlayback="stopPlayback"
      @restChange="updateRest"
    ></record>
  </div>
</template>

<script>
// Components
import Configure from "@/components/Config.vue";
import Record from "@/components/Record.vue";

// Libraries
const WebMidi = require("webmidi");
var _ = require("lodash");

export default {
  components: {
    Configure,
    Record
  },
  props: ["patchId", "toneReference", "isActive", "mode"],
  data() {
    return {
      patch: {},
      composition: {
        rest: 0,
        events: [
          {
            note: "C3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 0,
            time: "0:0:0",
            velocity: 0.7,
            active: false
          },
          {
            note: "E4",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 0,
            time: "0:0:0",
            velocity: 0.9,
            active: false
          },
          {
            note: "G3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 0,
            time: "0:0:0",
            velocity: 0.5,
            active: false
          },
          {
            note: "G3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 2,
            time: "0:2:0",
            velocity: 0.7,
            active: false
          },
          {
            note: "B4",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 2,
            time: "0:2:0",
            velocity: 0.9,
            active: false
          },
          {
            note: "D3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 2,
            time: "0:2:0",
            velocity: 0.5,
            active: false
          },
          {
            note: "F3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 4,
            time: "1:0:0",
            velocity: 0.7,
            active: false
          },
          {
            note: "A4",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 4,
            time: "1:0:0",
            velocity: 0.9,
            active: false
          },
          {
            note: "C4",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 4,
            time: "1:0:0",
            velocity: 0.5,
            active: false
          },
          {
            note: "A3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 6,
            time: "1:2:0",
            velocity: 0.7,
            active: false
          },
          {
            note: "C4",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 6,
            time: "1:2:0",
            velocity: 0.9,
            active: false
          },
          {
            note: "E3",
            rawDuration: 2,
            duration: "0:2:0",
            rawTime: 6,
            time: "1:2:0",
            velocity: 0.5,
            active: false
          }
        ]
      },
      part: {},
      recordingActive: false,
      playActive: false
    };
  },
  methods: {
    initPatch() {
      this.patch = {
        synth: new this.toneReference.PolySynth(),
        tremolo: new this.toneReference.Tremolo({ wet: 0 }).start(),
        vibrato: new this.toneReference.Vibrato({ wet: 0 }),
        chorus: new this.toneReference.Chorus({ wet: 0 }).start(),
        eq3: new this.toneReference.EQ3({ low: 0, mid: 0, high: 0 }),
        pingPongDelay: new this.toneReference.PingPongDelay({ wet: 0 }),
        feedbackDelay: new this.toneReference.FeedbackDelay({
          wet: 0,
          maxDelay: 5
        }),
        reverb: new this.toneReference.Reverb({ wet: 0 }),
        gain: new this.toneReference.Gain({gain: 1.0})
      };

      this.patch.synth.chain(
        this.patch.eq3,
        this.patch.tremolo,
        this.patch.vibrato,
        this.patch.chorus,
        this.patch.pingPongDelay,
        this.patch.feedbackDelay,
        this.patch.reverb,
        this.patch.gain,
        this.toneReference.Destination
      );
    },
    initWebMidi() {
      let vue = this;

      WebMidi.enable(function(err) {
        var input = WebMidi.inputs[0];
        input.addListener("noteon", "all", function(e) {
          if (vue.isActive) {
            let note = e.note.name + e.note.octave;

            // Play
            vue.patch.synth.triggerAttack(
              note,
              vue.toneReference.now(),
              e.velocity
            );

            if (vue.recordingActive) {
              // Recording
              let velocity = String(e.velocity).slice(0, 4)
              let rawTime = vue.toneReference.now() - vue.composition.start
              let quantizedTime = vue.toneReference.Time(vue.toneReference.Time(rawTime).quantize('16n')).toBarsBeatsSixteenths()

              let event = {
                note: note,
                velocity: velocity,
                active: true,
                rawTime: rawTime,
                time: quantizedTime
              };

              vue.composition.events.push(event);
            }
          }
        });

        input.addListener("noteoff", "all", function(e) {
          if (vue.isActive) {
            let note = e.note.name + e.note.octave;

            // Play
            vue.patch.synth.triggerRelease(note);

            if (vue.recordingActive) {
              // Recording
              let event = _.find(vue.composition.events, {
                note: note,
                active: true
              });
              let duration = vue.toneReference.now() - vue.composition.start - event.rawTime
              event.rawDuration = duration
              event.duration = vue.toneReference.Time(vue.toneReference.Time(duration).quantize('16n')).toBarsBeatsSixteenths()
              event.active = false;
            }
          }
        });
      });
    },
    // Composition Evens
    startRecording() {
      this.composition.start = this.toneReference.now();
      this.composition.events = [];
      this.recordingActive = true;
    },
    stopRecording() {
      this.recordingActive = false;
    },
    startPlayback() {
      let lastNote = _.last(this.composition.events);
      let endOfLoop = this.toneReference.Time(lastNote.time) + this.toneReference.Time(lastNote.duration)
      let rest = this.toneReference.Time(this.composition.rest)
      let interval = endOfLoop + rest;

      this.playActive = true;
      this.part = new this.toneReference.Part((time, event) => {
        this.patch.synth.triggerAttackRelease(
          event.note,
          event.duration,
          time,
          event.velocity
        );

        this.toneReference.Draw.schedule(() => {
          event.active = true;
        }, time);

        this.toneReference.Draw.schedule(() => {
          event.active = false;
        }, this.toneReference.Time(time) + this.toneReference.Time(event.duration));
      }, this.composition.events);
      this.part.loopEnd = interval;
      this.part.loop = true;

      this.part.start();
    },
    stopPlayback() {
      this.playAtive = false;
      this.part.stop();
    },
    // Instrument Config Changes
    updatePartials(partialsConfig) {
      this.patch.synth.set({
        oscillator: {
          type: "custom",
          partials: partialsConfig
        }
      });
    },
    updateEnvelope(envelopeConfig) {
      this.patch.synth.set({
        envelope: envelopeConfig
      });
    },
    updateTremolo(tremoloConfig) {
      this.patch.tremolo.set(tremoloConfig);
    },
    updateVibrato(vibratoConfig) {
      this.patch.vibrato.set(vibratoConfig);
    },
    updateEq3(eq3Config) {
      this.patch.eq3.set(eq3Config);
    },
    updateChorus(chorusConfig) {
      this.patch.chorus.set(chorusConfig);
    },
    updatePingPongDelay(pingPongDelayConfig) {
      this.patch.pingPongDelay.set(pingPongDelayConfig);
    },
    updateFeedbackDelay(feedbackDelayConfig) {
      this.patch.feedbackDelay.set(feedbackDelayConfig);
    },
    updateReverb(reverbConfig) {
      this.patch.reverb.set(reverbConfig);
    },
    updateGain(gainConfig) {
      this.patch.gain.set(gainConfig)
    },
    updateRest(newRest) {
      this.composition.rest = newRest;
    }
  },
  mounted() {
    this.initPatch();
    this.initWebMidi();
  }
};
</script>
